<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="form" :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：出纳-摘要</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="2" :sm="12" :md="8">
                                <el-button style=" margin-top: 8px; " type="primary" @click="addClass" size="mini">
                                    添加分类
                                </el-button>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :lg="2" :sm="12" :md="8">
                                <el-button style=" margin-top: 8px; " type="primary" round @click="searchEveryList"
                                           size="mini">搜索查询
                                </el-button>
                            </el-col>
                            <el-col :lg="2" :sm="12" :md="8">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="showClassDialogFormEvent">设置分类
                                </el-button>
                            </el-col>
                            <el-col :lg="2" :sm="12" :md="8">
                                <el-button style=" margin-top: 8px; " type="primary" @click="addSummary" size="mini">
                                    新增摘要
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="6" :sm="12" :md="8">
                        <el-row>
                            <vxe-grid ref="gridClass" v-bind="gridOptions" @checkbox-change="searchEveryList"
                                      @checkbox-all="searchEveryList">
                                <template #operateTree="{row}">
                                    <vxe-button icon="el-icon-edit-outline" title="修改" circle
                                                @click="editRowEvent(row)"></vxe-button>
                                    <vxe-button icon="el-icon-delete" title="删除" circle
                                                @click="removeRowEvent(row)"></vxe-button>
                                </template>
                            </vxe-grid>
                        </el-row>
                    </el-col>
                    <el-col :lg="18">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                height="670"
                                rowId="id"
                                size='medium'
                                highlight-hover-row
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :mouse-config="{selected: false}"
                                :checkbox-config="{labelField:'id',range:true}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #operate="{row}">
                                <vxe-button icon="el-icon-edit-outline" title="修改" circle
                                            @click="editSummaryRowEvent(row)"></vxe-button>
                                <vxe-button icon="el-icon-delete" title="删除" circle
                                            @click="removeSummaryRowEvent(row)"></vxe-button>
                            </template>
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>

                </el-row>

            </el-form>
        </el-main>
        <el-dialog :destroy-on-close="true" width="420px" :title="showEdit ? '编辑分类' : '新增分类'" :visible.sync="dialogFormVisible" center
                   :close-on-click-modal="false">
            <el-form ref="summaryTypeForm" :model="summaryTypeForm"
                     :rules="summaryTypeFormRules">
                <el-form-item label="常用摘要分类:" prop="name">
                    <el-input size="mini" v-model="summaryTypeForm.name"
                              :disabled="!summaryTypeForm.is_able_change" clearable autocomplete="off"
                              style="width: 52%;"></el-input>
                </el-form-item>
                <el-form-item label="分类类别:" prop="father_id" label-width="82px">
                    <el-cascader
                            :disabled="!summaryTypeForm.is_able_change"
                            size="mini" v-model="summaryTypeForm.father_id"
                            :options="gridOptions.data"
                            :props="{checkStrictly: true ,value:'id',label:'name'}"
                            clearable></el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveSummaryType('summaryTypeForm')">
                    确 定
                </el-button>
            </div>
        </el-dialog>
        <el-dialog width="420px" title="摘要名称分类设置" :visible.sync="classDialogFormVisible" center
                   :close-on-click-modal="false">
            <el-form :model="summaryClassForm">
                <el-alert
                        :title="'当前选择 '+checkedGoodsIds.length+' 个分类！'"
                        type="success"
                        :closable="false">
                </el-alert>
                <el-form-item label="分类类别:" prop="roles" label-width="82px">
                    <el-cascader
                            size="mini" v-model="summaryClassForm.classId"
                            :options="gridOptions.data"
                            :props="{checkStrictly: true ,value:'id',label:'name'}"
                            clearable></el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="classDialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveGoodsClass('setClass')">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog width="420px" :title="showSummaryEdit ? '编辑摘要' : '新增摘要'" :visible.sync="addSummaryDialogFormVisible" center
                   :close-on-click-modal="false">
            <el-form ref="summaryForm" :model="summaryForm"
                     :rules="summaryFormRules">
                <el-form-item label="常用摘要名称:" prop="summary_name">
                    <el-input size="mini" v-model="summaryForm.summary_name" clearable autocomplete="off"
                              style="width: 52%;"></el-input>
                </el-form-item>
                <el-form-item label="分类类别:" prop="summary_type_id" label-width="82px">
                    <el-cascader
                            size="mini" v-model="summaryForm.summary_type_id"
                            :options="gridOptions.data"
                            :props="{checkStrictly: true ,value:'id',label:'name'}"
                            clearable></el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addSummaryDialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveSummary('summaryForm')">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>
    import XEUtils from 'xe-utils'

    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            return {
                //分类
                dialogFormVisible: false,
                classDialogFormVisible: false,
                addSummaryDialogFormVisible: false,
                showEdit: false,
                showSummaryEdit: false,
                summaryForm:{
                    id: '',
                    summary_name: '',
                    summary_key_code: '',
                    summary_type_id: '',
                    summary_type: '',
               },
                summaryFormRules: {
                    summary_name: [
                        {required: true, message: "请输入：常用摘要名称", trigger: "blur"},
                    ],
               },
                summaryClassForm: {
                    summary_type_id: '',
               },
                summaryTypeForm: {
                    id: '',
                    name: '',
                    is_able_change: true,
                    father_id : '',
               },
                summaryTypeFormRules: {
                    name: [
                        {required: true, message: "请输分类名称", trigger: "blur"},
                    ],
               },
                gridOptions: {
                    resizable: true,
                    showOverflow: true,
                    highlightHoverRow: true,
                    keepSource: true,
                    rowId: 'id',
                    size: 'medium',
                    headerRowStyle: {height: '76px'},
                    ref: 'gridTree',
                    treeConfig: {expandAll: true, reserve: true},
                    editConfig: {
                        trigger: 'manual',
                        mode: 'row',
                        showStatus: true,
                   },
                    exportConfig: {},
                    checkboxConfig: {
                        labelField:'id'
                   },
                    data: [],
                    columns: [
                        {title: '全选', type: 'checkbox', field:'id', width: 80},
                        {title: '常用摘要分类', field:'name',width: 180, treeNode: true},
                        {title: '编辑', width: 110, slots: {default: 'operateTree'}},
                    ]
               },
                formData: {//搜索筛选
                    name: null,
                    showCancel: false,
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                tableColumn: [
                    {type: 'checkbox', title: '全选', field:'id', width: 70},
                    {
                        field:'summary_name', width: 220, title: '摘要', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/summary/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   }
                    , {
                        field:'summary_key_code', width: 120, title: '辅助编码', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/summary/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   }
                    , {
                        field:'summary_type_id', width: 100, title: '所属分类', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/summary/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                    {
                        field:'summaryType', width: 165, title: '分类名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/summary/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                    {title: '编辑', width: 110, slots: {default: 'operate'}},
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {"id":"desc"},
                pickerDate: new Date(),
                activeName: 'first',
                checkedGoodsIds: [],
                checkedGridClassIds: [],

           }
       },
        computed: {},

        methods: {

            //设置商品分类、商品同步、商品限价
            saveGoodsClass(typeAction) {
                let url = ''
                if (typeAction == 'setClass') {
                    url = '/admin/summary/saveGoodsClass'
               } else if (typeAction == 'setPrice') {
                    url = '/admin/summary/setPriceGoods'
               }
                this.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        date: this.pickerDate,
                        checkedGoodsIds: this.checkedGoodsIds,
                        classId: this.summaryClassForm.classId,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '操作成功',
                            type: 'success'
                       });
                        this.classDialogFormVisible = false;
                        this.searchEveryList();
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            showClassDialogFormEvent() {
                this.classDialogFormVisible = true;
                this.goodsClassCheckedEvent();
                //console.log(this.getSelectionEvent('xGrid'));
           },
            addSummary(){
                this.addSummaryDialogFormVisible = true;
                this.showSummaryEdit = false;
           },
            goodsClassCheckedEvent() {
                this.checkedGoodsIds = this.getSelectionEvent('xGrid');
           },
            addClass() {
                this.dialogFormVisible = true;
                this.showEdit = false;
                this.summaryTypeForm.father_id = '';
                this.summaryTypeForm.name = '';
                this.summaryTypeForm.id = '';
                this.summaryTypeForm.is_able_change = true;
           },
            saveSummary(formName) {
                let url = '';
                if (this.showSummaryEdit) {
                    url = '/admin/summary/updateSummary';
               } else {
                    url = '/admin/summary/saveSummary';
               }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios({
                            method: 'post',
                            url: url,
                            data: this.summaryForm,
                       }).then((response) => {         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '保存成功',
                                    type: 'success'
                               });
                                this.addSummaryDialogFormVisible = false;
                                this.searchEveryList();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   } else {
                        //console.log('error submit!!');
                        return false;
                   }
               });
           },
            saveSummaryType(formName) {
                let url = '';
                if (this.showEdit) {
                    url = '/admin/summary/updateClass';
               } else {
                    url = '/admin/summary/saveClass';
               }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios({
                            method: 'post',
                            url: url,
                            data: this.summaryTypeForm,
                       }).then((response) => {         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '保存成功',
                                    type: 'success'
                               });
                                this.dialogFormVisible = false;
                                this.findSummaryType();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   } else {
                        //console.log('error submit!!');
                        return false;
                   }
               });
           },
            findSummaryType() {
                this.$axios({
                    method: 'POST',
                    url: '/admin/summary/findSummaryType',
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.gridOptions.data = response.data
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.searchEveryList();
           },
            editRowEvent(row) {
                this.showEdit = true;
                this.dialogFormVisible = true;
                this.summaryTypeForm.id = row.id;
                this.summaryTypeForm.name = row.name;
                this.summaryTypeForm.is_able_change = row.is_able_change;
                this.summaryTypeForm.father_id = row.father_id;
                //console.log(this.summaryTypeForm.father_id);
           },
            editSummaryRowEvent(row) {
                this.showSummaryEdit = true;
                this.addSummaryDialogFormVisible = true;
                this.summaryForm.id = row.id;
                this.summaryForm.summary_name = row.summary_name;
                this.summaryForm.summary_key_code = row.summary_key_code;
                this.summaryForm.summary_type_id = row.summary_type_id;
                this.summaryForm.summary_type = row.summary_type;
           },
            removeRowEvent(row) {
                this.$XModal.confirm('您确定要删除 ' + row.name + ' 分类（子分类 将变成 父类）?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/summary/deleteClass',
                            params: {
                                id: row.id
                           }
                       }).then((response) => {         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                this.findSummaryType();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   }
               })
           },
            removeSummaryRowEvent(row) {
                this.$XModal.confirm('您确定要删除 ' + row.summary_name + '?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/summary/deleteSummary',
                            params: {
                                id: row.id
                           }
                       }).then((response) => {         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                this.searchEveryList();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   }
               })
           },
            getSelectionEvent(ref) {
                const $grid = this.$refs[ref];
                const selectRecords = $grid.getCheckboxRecords();
                let ids = selectRecords.map((item) => item.id);
                return ids;
           },
            searchEveryList() {
                this.checkedGridClassIds = this.getSelectionEvent('gridClass');
                this.tableDataMain = [];
                this.$axios({
                    method: 'post',
                    url: '/admin/summary/searchEveryList',
                    data: {
                        checkedGridClassIds: this.checkedGridClassIds,
                        tablePage: this.tablePage,
                        sortData:this.sortData,
                        filterData:this.filterData,
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '查询成功',
                            type: 'success'
                       });
                        this.tableDataMain = response.data.summary.list;
                        this.tablePage.total = response.data.summary.totalRow;
                        this.tablePage.currentPage = response.data.summary.pageNumber;
                        this.tablePage.pageSize = response.data.summary.pageSize;
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
       },
        created() {
            this.findSummaryType();
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })

       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /*    .el-form-item__content .el-input{
            width: 80% !important;
       }*/
    /deep/ .el-form-item__error {
        position: initial !important;
   }

    .el-tag + .el-tag {
        margin-left: 10px;
        margin-top: 10px;
   }

    /deep/ .el-input--suffix .el-input__inner{
        padding-right: initial!important;
   }

    /deep/ input[popperclass="myAutocomplete"]{
        padding:3px!important;
   }
    /deep/ .el-input__suffix {
        right: 0px!important;
   }
</style>
